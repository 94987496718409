<template>
    <div class="d-flex align-items-center flex-row-reverse" style="gap: 20px">
        <ek-dialog
            v-if="getUserRoles() == Admin || getUserRoles() == SuperAdmin"
            ref="addDialog"
            title="إضافة مادة"
            size="md"
            btnText="اضافة مادة"
            @ok="submit()"
            @close="reset"
        >
            <template #body>
                <ValidationObserver ref="subjectForm">
                    <b-form>
                        <ek-input-text
                            :rules="[
                                {
                                    type: 'required',
                                    message: '  اسم المادة مطلوب',
                                },
                            ]"
                            name="name"
                            placeholder=" ادخل اسم المادة"
                            label="  اسم المادة"
                            v-model="subjectDto.name"
                        ></ek-input-text>
                        <ek-input-select
                            :rules="[
                                {
                                    type: 'required',
                                    message: '  يرجى اختيار الجامعة',
                                },
                            ]"
                            :options="universityList"
                            name="classes"
                            v-model="subjectDto.universityId"
                            placeholder="تابع للجامعة"
                            label="الجامعة"
                            clearable
                            @change="resetFaculty"
                        ></ek-input-select>

                        <ek-input-select
                            v-if="subjectDto.universityId"
                            :rules="[
                                {
                                    type: 'required',
                                    message: '  يرجى اختيار كلية',
                                },
                            ]"
                            :options="facultyList"
                            name="classes"
                            v-model="subjectDto.facultyId"
                            placeholder="تابع للكلية"
                            label="الكلية"
                            clearable
                            @change="resetDepartment"
                        ></ek-input-select>
                        <!-- <pre>  {{ universityList }}</pre> -->
                        <ek-input-select
                            v-if="subjectDto.facultyId"
                            :options="branchList"
                            clearable
                            placeholder="تابعة للفرع"
                            name=" اختر الفرع"
                            label="الفرع"
                            v-model="subjectDto.departmentId"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'الفرع مطلوب',
                                },
                            ]"
                        >
                        </ek-input-select>

                        <b-row no-gutters>
                            <b-col lg="6" md="12">
                                <ek-input-select
                                    :options="years"
                                    placeholder="السنة"
                                    name=" اختر السنة"
                                    label="السنة"
                                    v-model="subjectDto.yearId"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'السنة مطلوبة',
                                        },
                                    ]"
                                >
                                </ek-input-select>
                            </b-col>
                            <b-col lg="6" md="12">
                                <ek-input-select
                                    :options="semesters"
                                    placeholder="اختر الفصل"
                                    name=" اختر الفصل"
                                    label="الفصل"
                                    v-model="subjectDto.semesterId"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'الفصل مطلوب',
                                        },
                                    ]"
                                >
                                </ek-input-select>
                            </b-col>
                        </b-row>
                        <ek-input-text
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'سعر المادة مطلوب',
                                },
                            ]"
                            name="price"
                            placeholder="سعر المادة "
                            label="سعر المادة"
                            type="number"
                            v-model="subjectDto.price"
                        ></ek-input-text>
                        <ek-input-text
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'علامة المادة مطلوب',
                                },
                            ]"
                            name="price"
                            placeholder="علامة المادة "
                            label="علامة المادة الكلية"
                            type="number"
                            v-model="subjectDto.mark"
                        ></ek-input-text>
                        <ek-input-image
                            ref="imgFile"
                            label=" صورة المادة "
                            name="صور "
                            @input="uploadSubFile"
                            accept="image/png, image/jpeg"
                        >
                            <h5>انقر لتحميل الصورة</h5>
                        </ek-input-image>
                        <ek-input-textarea
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'شرح المادة مطلوب',
                                },
                            ]"
                            name="description"
                            placeholder="شرح المادة"
                            v-model="subjectDto.description"
                            label="شرح المادة"
                        ></ek-input-textarea>
                    </b-form>
                </ValidationObserver>
            </template>
        </ek-dialog>

        <ek-input-text
            v-model="filterDto.search"
            placeholder="ابحث عن مادة"
        ></ek-input-text>
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions, mapGetters } from "vuex";
import { getUserRoles } from "@/app/admin/auth/utils";
import { SuperAdmin, Admin } from "@/router";
export default {
    components: {
        ValidationObserver,
    },
    data: () => ({
        SuperAdmin,
        Admin,
    }),
    computed: {
        ...mapState({
            subjectDto: (state) => state.subjects.subjectDto,
            filterDto: (state) => state.subjects.filterDto,
            url: (state) => state.subjects.url,
            universityList: (state) => state.university.universityList,
            years: (state) => state.settings.yearList,
            semesters: (state) => state.settings.semesterList,
            branchList: (state) => state.branches.branchList,
            facultyList: (state) => state.faculty.facultyList,
        }),
        ...mapGetters(["getUrl"]),
    },
    methods: {
        ...mapActions([
            "addSubject",
            "getSettingYear",
            "getSettingSemester",
            "getBranchList",
            "addFile",
        ]),
        getUserRoles,
        submit() {
            this.$refs["subjectForm"].validate().then((success) => {
                if (success) {
                    this.addSubject({ ...this.subjectDto, url: this.url }).then(
                        (data) => {
                            this.$store.commit("Add_Courses_Subjects", data);
                            this.$store.dispatch("getSubjectsList", {
                                universityId: "",
                                facultyId: "",
                                yearId: "",
                                semesterId: "",
                                pageIndex: 1,
                                pageSize: 4,
                            });
                        }
                    );
                    console.log(getUserRoles(), "test");
                    this.$refs.addDialog.close();
                    this.$store.commit("RESET_SUBJECT");
                    this.$refs.subjectForm.reset();
                }
            });
        },

        resetFaculty() {
            this.subjectDto.universityId = "";
            this.subjectDto.facultyId = "";
        },
        resetDepartment() {
            this.subjectDto.facultyId = "";
            this.subjectDto.departmentId = "";
        },
        reset() {
            this.$refs.subjectForm.reset();
            this.$refs.imgFile.reset();
            this.$store.commit("RESET_SUBJECT");
            Object.assign(this.subjectDto, {
                description: "",
                price: "",
                semesterId: "",
                yearId: "",
                facultyId: "",
                universityId: "",
                name: "",
            });
            this.$refs.addDialog.close();
        },
        uploadSubFile(event) {
            if (event.file) {
                this.addFile({ ...event, folderName: "Profile" });
            }
        },
    },
    created() {
        this.getUserRoles();
    },
    beforeDestroy() {
        this.$store.commit("Reset_Subject");
    },
};
</script>

<style>
#color {
    width: 30px !important;
    height: 30px !important;
}
</style>
